<template>
    <div>
    <TitleAdmin msg="import question" routerAdmin="importQuestion"/>
    </div>
</template>
<script>
import TitleAdmin from '@/views/constants/titleAdmin.vue'
export default {
    components:{
        TitleAdmin
    }
}
</script>